var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"multi-line":true,"timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]),_c('v-dialog',{attrs:{"hide-overlay":"","no-click-animation":"","persistent":"","width":"500"},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}},[_c('v-card',{attrs:{"elevation":"8"}},[_c('v-card-title',{staticStyle:{"background-color":"#003366"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Conferma")]),_vm._v(" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.updateDialog = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close-thick")])],1)],1),_c('v-card-text',[_c('v-file-input',{ref:"imageUpload",attrs:{"accept":"*/*","label":"Aggiungi allegato"},on:{"change":_vm.setFile},model:{value:(_vm.attFilename),callback:function ($$v) {_vm.attFilename=$$v},expression:"attFilename"}}),_c('v-textarea',{attrs:{"label":"Note"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),(_vm.sendEvent)?_c('v-card-title',[_vm._v(_vm._s(_vm.getUserEventText(_vm.sendEvent))),_c('br'),_vm._v("Confermi? ")]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","disabled":_vm.needEvent()},on:{"click":function($event){return _vm.updatePurchase(_vm.purchase.ID, _vm.sendEvent, _vm.sendMethod)}}},[_vm._v("OK")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.updateDialog = false;
            _vm.timerDate = null;}}},[_vm._v("Annulla")])],1)],1)],1),(_vm.globalStep)?_c('v-dialog',{attrs:{"hide-overlay":"","no-click-animation":"","persistent":"","width":"500"},model:{value:(_vm.detailsDialog),callback:function ($$v) {_vm.detailsDialog=$$v},expression:"detailsDialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"background-color":"#003366"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Dettagli")])]),_c('v-card-text',[_c('v-timeline',{attrs:{"dense":""}},_vm._l((_vm.globalStep.insideSteps.reverse()),function(inside,i){return _c('v-timeline-item',{key:'ciccio' + i,attrs:{"small":"","color":_vm.myFSM.states[inside.state].meta.color},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-arrow-up")])]},proxy:true}],null,true)},[_c('v-card',{attrs:{"elevation":"4"}},[_c('span',[_vm._v(_vm._s(inside.User.COGNOME)+" "+_vm._s(inside.User.NOME))]),_vm._v(":"),_c('br'),_vm._v(_vm._s(_vm.formatStep(inside))),_c('br'),(
                  inside.comment &&
                  inside.comment !== null &&
                  inside.comment !== ''
                )?_c('span',[_vm._v("Note: "+_vm._s(inside.comment))]):_vm._e(),_c('br'),(
                    inside.financialAssignee &&
                    inside.financialAssignee !== null &&
                    inside.financialAssignee !== ''
                  )?_c('span',[_vm._v("Assegnato a: "+_vm._s(inside.financialAssignee.COGNOME)+" "+_vm._s(inside.financialAssignee.NOME))]):_vm._e(),_c('br'),(inside.attachment)?_c('span',[_vm._v("Allegato: "),(!_vm.attachmentDeleted(inside.attachment))?_c('a',{on:{"click":function($event){return _vm.downloadAttachment(inside.attachment)}}},[_vm._v(_vm._s(_vm.attachmentFilename(inside.attachment)))]):_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(_vm.attachmentFilename(inside.attachment)))])]):_vm._e(),_c('br')])],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.detailsDialog = false}}},[_vm._v("Chiudi")])],1)],1)],1):_vm._e(),(_vm.loading)?_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"1"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":70,"width":7}})],1),_c('v-spacer')],1):(_vm.error)?_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.errorText))])],1),_c('v-spacer')],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[(_vm.myFSM)?_c('v-card',{attrs:{"elevation":"8"}},[_c('v-card-title',[_vm._v("Richiesta: "+_vm._s(_vm.purchaseDescription)+" ")]),_c('v-card-text',[_c('strong',[_vm._v(" Stato attuale: "+_vm._s(_vm.myFSM.states[_vm.currentState].meta.fullStateName)),_c('br'),_vm._v(" Utente (Richiedente): "),_vm._l((_vm.purchase.Users),function(user,j){return _c('span',{key:j},[_vm._v(_vm._s(user.COGNOME)+" "+_vm._s(user.NOME)),(j !== _vm.purchase.Users.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])}),_c('br'),(_vm.currentMethod)?_c('span',[_vm._v("Tipologia di acquisto: "+_vm._s(_vm.currentMethodText)),_c('br')]):_vm._e(),(!_vm.purchase.closed)?_c('span',[_vm._v("Attualmente assegnato a: "+_vm._s(_vm.myFSM.states[_vm.currentState].meta.responsible)),(
                  _vm.financialAssignee &&
                  _vm.myFSM.states[_vm.currentState].meta.financial
                )?_c('span',[_vm._v(", "+_vm._s(_vm.financialAssignee.COGNOME)+" "+_vm._s(_vm.financialAssignee.NOME))]):_vm._e()]):_vm._e()],2),_c('br'),_c('br'),(_vm.attachments.length > 0)?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{attrs:{"cols":"3","align-self":"center"}},[_c('strong',[_vm._v("Allegati:")]),_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.attachments.length))])],1),_c('v-col',{attrs:{"cols":"3","align-self":"center"}},[_c('strong',[_vm._v("inserito:")])]),_c('v-col',{attrs:{"cols":"3","align-self":"center"}},[_c('strong',[_vm._v("da:")])]),_c('v-col',{attrs:{"cols":"3","align-self":"center"}},[_c('strong',[_vm._v("nella fase:")])])],1)],1),_vm._l((_vm.attachments),function(attachment){return _c('v-expansion-panel-content',{key:attachment.id,class:attachment.deleted ? 'text-decoration-line-through text--disabled' : '',attrs:{"dense":""}},[_c('v-row',{class:attachment.starred
                          ? 'font-weight-bold grey lighten-3'
                          : ''},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v("- "),(!attachment.deleted)?_c('a',{on:{"click":function($event){return _vm.downloadAttachment(attachment.id)}}},[_vm._v(_vm._s(attachment.ORIGINAL_FILENAME))]):_c('span',[_vm._v(_vm._s(attachment.ORIGINAL_FILENAME))])]),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(_vm._s(_vm.formatTime(attachment.state.date)))]),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(_vm._s(attachment.state.User.COGNOME)+" "+_vm._s(attachment.state.User.NOME))]),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(_vm._s(_vm.myFSM.states[attachment.state.state].meta.fullStateName))])],1)],1)})],2)],1):_vm._e(),_c('br'),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(_vm.myFSM.states[_vm.currentState].meta.userInteractionText)+" ")]),_c('v-spacer')],1)],1),(_vm.myFSM.states[_vm.currentState].meta.userInteraction)?_c('v-card-actions',[_c('v-row',[_c('v-spacer'),_vm._l((_vm.myFSM.states[_vm.currentState].meta.userButtons),function(button){return _c('v-col',{key:button.text,attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":function($event){_vm.comment = null;
                  _vm.sendEvent = button.event ? button.event : _vm.computedEvent;
                  _vm.sendMethod = button.method;
                  _vm.notifyUsers = true;
                  _vm.notifyGroup = true;
                  _vm.updateDialog = true;}}},[_vm._v(_vm._s(button.text))])],1)}),_c('v-spacer')],2),_c('br')],1):_vm._e(),_c('br')],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',{attrs:{"elevation":"8"}},[_c('v-card-title',[_vm._v("Timeline")]),_c('v-card-text',[(_vm.purchase)?_c('v-timeline',{attrs:{"reverse":false}},_vm._l((_vm.reducedReversedHistory),function(step,l){return _c('v-timeline-item',{key:l,class:l % 2 ? '' : 'text-right',attrs:{"small":"","fill-dot":"","color":_vm.myFSM.states[step.state].meta.color},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"medium":"","dark":""}},[_vm._v("mdi-arrow-up")])]},proxy:true},{key:"opposite",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.formatStep(step)))]),_c('br'),_vm._v(_vm._s(_vm.getEventText(step.event))+" ")]},proxy:true}],null,true)},[_c('v-alert',{staticClass:"white--text",attrs:{"value":true,"color":_vm.myFSM.states[step.state].meta.color}},[_c('strong',[_vm._v(_vm._s(_vm.myFSM.states[step.state].meta.fullStateName))]),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.myFSM.states[step.state].meta.responsible))]),_c('br'),_c('br'),_c('v-btn',{on:{"click":function($event){_vm.globalStep = step;
                    _vm.detailsDialog = true;}}},[_vm._v("Dettagli")])],1)],1)}),1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }